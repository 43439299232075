import { client } from '@admin/libraries/apollo';
import * as React from 'react';
import { useRegionsSelectorQuery } from '@admin/schema';

import { MultiselectFormControl } from '@admin/components/fields';

export const Selector: React.FC<{
  selectedIDs: string[];
  disabled?: boolean;
  onChange(selectedIDs: string[]): void;
}> = ({ onChange, disabled, selectedIDs }) => {
  const { data, loading } = useRegionsSelectorQuery({ client });
  const regions = data?.regions ?? [];

  return (
    <MultiselectFormControl
      placeholder="Regions"
      options={regions}
      selectedIDs={selectedIDs}
      onChange={onChange}
      disabled={disabled || loading}
    />
  );
};
