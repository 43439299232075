import React, { useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { Item } from './reorderable_list/item';

type Item<Props> = {
  props: React.PropsWithChildren<Props>;
  key: string;
  component: (props: React.PropsWithChildren<Props>) => ReturnType<React.FC>;
};

export const ReorderableList = <Props,>({
  items,
  setItems,
  wrapperTag: Wrapper = Item,
}: {
  items: Array<Item<Props>>;
  setItems: (items: Array<Item<Props>>) => void;
  wrapperTag?: React.ElementType<any>;
}) => {
  const [cloned, setCloned] = useState(items);

  // Ensure that external changes to the list of items are copied into our cloned set
  useEffect(() => {
    setCloned(items);
  }, [items]);

  const moveItem = (dragIndex: number, hoverIndex: number) => {
    const newItems = [...cloned];
    const movedItem = newItems[dragIndex];
    const replacedItem = newItems[hoverIndex];
    newItems[hoverIndex] = movedItem;
    newItems[dragIndex] = replacedItem;
    setCloned(newItems);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      {cloned.map(({ component: Component, props, key }, index) => (
        <Item key={key} id={key} moveItem={moveItem} index={index} onFinish={() => setItems(cloned)} tag={Wrapper}>
          <Component {...props} />
        </Item>
      ))}
    </DndProvider>
  );
};
