import React, { useEffect, useState } from 'react';
import { Formik, Form as FormikForm, useFormikContext } from 'formik';

import { Panel } from '@admin/components/helpers/panel';
import {
  Admin__CouponFragment,
  Coupon__Duration,
  Coupon__Input,
  Coupon__Service,
  Coupon__Subject,
  Status,
} from '@admin/schema';
import { AnchorButton as Link, Button, InputGroup, Text } from '@shared/components/bootstrap';

import { FetchResult } from '@apollo/client';
import { Box } from '@clutter/clean';
import { FormikInputFormGroup } from '@shared/components/fields/formik/formik_input_form_group';
import { FormikFieldFormGroup } from '@shared/components/fields/formik/formik_field_form_group';
import { FormikInputFormControl } from '@shared/components/fields/formik/formik_input_form_control';
import { FormikSelectFormGroup } from '@shared/components/fields/formik/formik_select_form_group';
import { FormikCheckboxFormGroup } from '../fields/formik/formik_checkbox_form_group';

type FormValues = {
  stripeId: string;
  name: string;
  code: string;
  duration: Coupon__Duration;
  durationInMonths?: number;
  amount: string;
  percent: string;
  movingEligible: boolean;
  storageOnboardingEligible: boolean;
  scheduledCutoff?: string;
  type: string;
  subject: Coupon__Subject;
};

const AmountField: React.FC<{ disabled: boolean }> = ({ disabled }) => {
  const {
    values: { type },
  } = useFormikContext<{
    type: 'dollar' | 'percent';
  }>();

  const name = type === 'dollar' ? 'amount' : 'percent';

  return (
    <FormikFieldFormGroup name={name} label="Amount">
      <InputGroup>
        {type === 'dollar' && (
          <>
            <InputGroup.Addon>$</InputGroup.Addon>
            <FormikInputFormControl name={name} type="number" step="0.01" min="0" disabled={disabled} required />
          </>
        )}
        {type === 'percent' && (
          <>
            <FormikInputFormControl
              name={name}
              type="number"
              step="0.01"
              min="0"
              max="100"
              disabled={disabled}
              required
            />
            <InputGroup.Addon>%</InputGroup.Addon>
          </>
        )}
      </InputGroup>
    </FormikFieldFormGroup>
  );
};

const DurationInMonthsField: React.FC<{ disabled: boolean }> = ({ disabled }) => {
  const {
    values: { duration },
  } = useFormikContext<FormValues>();
  if (duration !== Coupon__Duration.Repeating) return null;
  return <FormikInputFormGroup disabled={disabled} type="number" name="durationInMonths" label="Duration in Months" />;
};

const ExpiryAtField: React.FC = () => (
  <FormikFieldFormGroup name="scheduledCutoff" label="Appointment Date Cutoff (optional)">
    <InputGroup>
      <FormikInputFormControl name="scheduledCutoff" type="date" />
    </InputGroup>
  </FormikFieldFormGroup>
);

const DurationField: React.FC<{ disabled: boolean }> = ({ disabled }) => {
  const {
    values: { subject, duration },
    setFieldValue,
  } = useFormikContext<FormValues>();

  const oneTimeOnlySubject = subject === Coupon__Subject.Credit || subject === Coupon__Subject.Order;

  useEffect(() => {
    if (disabled || !oneTimeOnlySubject) return;
    if (duration !== Coupon__Duration.Once) {
      setFieldValue('duration', Coupon__Duration.Once);
    }
  }, [disabled, oneTimeOnlySubject, duration, setFieldValue]);

  return (
    <FormikSelectFormGroup name="duration" label="Duration" disabled={disabled || oneTimeOnlySubject}>
      <option value={Coupon__Duration.Once}>Once</option>
      {!oneTimeOnlySubject && (
        <>
          <option value={Coupon__Duration.Forever}>Forever</option>
          <option value={Coupon__Duration.Repeating}>Repeating</option>
        </>
      )}
    </FormikSelectFormGroup>
  );
};

const TypeField: React.FC<{ disabled: boolean }> = ({ disabled }) => {
  const {
    values: { subject, duration, type },
    setFieldValue,
  } = useFormikContext<FormValues>();

  useEffect(() => {
    if (subject === Coupon__Subject.Credit && type !== 'dollar') {
      setFieldValue('type', 'dollar');
    }
  }, [subject, duration, setFieldValue, type]);
  return (
    <FormikSelectFormGroup name="type" label="Type" disabled={disabled}>
      <option value="percent">Percent</option>
      <option value="dollar">Dollar</option>
    </FormikSelectFormGroup>
  );
};

export const CouponForm: React.FC<{
  coupon?: Admin__CouponFragment;
  onSave(): void;
  loading: boolean;
  save(input: Coupon__Input): Promise<
    FetchResult<{
      result: {
        status: Status;
        error?: string | null;
      };
    }>
  >;
}> = ({ coupon, onSave, save, loading }) => {
  const [formError, setFormError] = useState<string | undefined>();

  const onSubmit = async (values: FormValues) => {
    if (loading) return;

    const eligibleServices = [];
    if (values.movingEligible) {
      eligibleServices.push(Coupon__Service.Moving);
    }
    if (values.storageOnboardingEligible) {
      eligibleServices.push(Coupon__Service.StorageOnboarding);
    }

    const response = await save({
      stripeId: values.stripeId,
      name: values.name,
      active: !!coupon?.active,
      duration: values.duration,
      durationInMonths: values.durationInMonths,
      amount: values.type === 'dollar' ? Number(values.amount) : 0,
      percent: values.type === 'percent' ? Number(values.percent) / 100 : 0,
      eligibleServices,
      scheduledCutoff: values.scheduledCutoff || null,
      promoCode: {
        value: values.code,
      },
      subject: values.subject,
    });

    if (response.data?.result.error) {
      setFormError(response.data.result.error);
      return;
    }

    if (response.data?.result.status === Status.Ok) {
      setFormError(undefined);
      onSave();
    }
  };

  const disabled = !!coupon?.effective;

  return (
    <Panel>
      <Formik
        initialValues={{
          stripeId: coupon?.stripeId ?? '',
          scheduledCutoff: coupon?.scheduledCutoff ?? '',
          name: coupon?.name ?? '',
          code: coupon?.code ?? '',
          type: coupon && coupon.percent > 0 ? 'percent' : 'dollar',
          duration: coupon?.duration ?? Coupon__Duration.Once,
          amount: coupon?.amount.toString() ?? '',
          percent: coupon ? (coupon.percent * 100).toString() : '',
          movingEligible: !!coupon?.eligibleServices.includes(Coupon__Service.Moving),
          storageOnboardingEligible: !!coupon?.eligibleServices.includes(Coupon__Service.StorageOnboarding),
          subject: coupon?.subject ?? Coupon__Subject.Subscription,
        }}
        onSubmit={onSubmit}
      >
        <FormikForm>
          <Panel.Header>
            <Panel.Title>Details</Panel.Title>
          </Panel.Header>
          <Panel.Body>
            <Box.Flex gap={['initial', '64px']} flexDirection={['column', 'row']}>
              <Box.FlexItem flexBasis="50%">
                <FormikInputFormGroup name="stripeId" label="Stripe Name" type="text" disabled={disabled} required />
                <FormikInputFormGroup name="name" label="Description" type="text" required />
                <FormikSelectFormGroup name="subject" label="Subject" disabled={disabled}>
                  <option value={Coupon__Subject.Credit}>Credit</option>
                  <option value={Coupon__Subject.Order}>Order</option>
                  <option value={Coupon__Subject.Subscription}>Subscription</option>
                </FormikSelectFormGroup>
                <DurationField disabled={disabled} />
                <DurationInMonthsField disabled={disabled} />
                <TypeField disabled={disabled} />
                <AmountField disabled={disabled} />
                {coupon && <FormikInputFormGroup name="code" label="Promo Code" maxLength={12} />}
              </Box.FlexItem>
              <Box.FlexItem flexBasis="50%">
                <ExpiryAtField />
                <Box.Flex gap="64px" alignItems="center">
                  <p>Eligible Services</p>
                  <FormikCheckboxFormGroup
                    label="Storage Onboarding"
                    id="eligible_services"
                    name="storageOnboardingEligible"
                  />
                  <FormikCheckboxFormGroup label="Moving" id="eligible_services" name="movingEligible" />
                </Box.Flex>
              </Box.FlexItem>
            </Box.Flex>
          </Panel.Body>
          {formError && (
            <Text tag="p" style="danger">
              <strong>{formError}</strong>
            </Text>
          )}
          <Panel.Footer align="right">
            <Link kind="light" href="/marketing/coupons">
              Cancel
            </Link>
            <Button kind="primary" type="submit">
              Save
            </Button>
          </Panel.Footer>
        </FormikForm>
      </Formik>
    </Panel>
  );
};
