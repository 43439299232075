import { FacilityInput__Kind } from '@admin/schema';
import { DateTime, Interval } from 'luxon';

export const getFacilityInput = (splittableFacilityID?: string | null) => {
  if (!splittableFacilityID) {
    return undefined;
  }

  const [facilityType, rawFacilityID] = splittableFacilityID.split('/');

  if (facilityType && rawFacilityID) {
    return {
      id: rawFacilityID,
      type: facilityType === 'Warehouse' ? FacilityInput__Kind.Warehouse : FacilityInput__Kind.Depot,
    };
  } else {
    return undefined;
  }
};

const getIntervalInMinutes = (started: string | DateTime, ended: string | DateTime) =>
  Interval.fromDateTimes(
    started instanceof DateTime ? started : DateTime.fromISO(started),
    ended instanceof DateTime ? ended : DateTime.fromISO(ended),
  ).length('minutes');

export const calculateDuration = (
  started: string | DateTime,
  ended: string | DateTime,
  workBreaks: Array<{ started?: string | DateTime | null; ended?: string | DateTime | null }>,
) => {
  let duration = 0;
  if (started && ended) {
    const shiftLength = getIntervalInMinutes(started, ended);
    const breakLength =
      workBreaks
        .filter((wb) => wb.started && wb.ended)
        .reduce((minutes, wb) => minutes + getIntervalInMinutes(wb.started as string, wb.ended as string), 0) || 0;
    duration = shiftLength > breakLength ? shiftLength - breakLength : 0;
  }
  return Math.ceil(duration);
};
