import { Order, OrderServiceTypeEnum, OrderTypeEnum } from '@admin/schema';
import { DISPLAYED_SERVICE_TYPE_MAP } from '@shared/utils/order';
import React from 'react';

type DisplayOrder = Pick<Order, 'serviceType' | 'type' | 'auctionPickup'>;
type AngularOrder = { service_type: string; type: string; auction_pickup: boolean };

const formattedServiceType = (order: DisplayOrder) => {
  if (!order.serviceType) {
    return '';
  }

  if (order.serviceType === OrderServiceTypeEnum.FullService && order.type === OrderTypeEnum.Pickup) {
    return 'Pickup & Packing';
  }

  if (order.auctionPickup) {
    return 'Facility (Auction Pickup)';
  }

  return DISPLAYED_SERVICE_TYPE_MAP[order.serviceType];
};

const parseOrder = (order: DisplayOrder | AngularOrder): DisplayOrder => {
  if ('service_type' in order) {
    return {
      serviceType: order.service_type.toUpperCase() as OrderServiceTypeEnum,
      type: order.type.toUpperCase() as OrderTypeEnum,
      auctionPickup: order.auction_pickup,
    };
  } else {
    return order;
  }
};

export const FormattedServiceType: React.FC<{ order: DisplayOrder | AngularOrder }> = ({ order }) => (
  <>{formattedServiceType(parseOrder(order))}</>
);
