import React, { useMemo } from 'react';

import { Workforce__FacilityFragment } from '@admin/schema';

interface IGroupedOption {
  label: string;
  options: IOption[];
}

interface IOption {
  value: string;
  label: string;
}

const getGroupedOptions = (facilities: Workforce__FacilityFragment[]): IGroupedOption[] => {
  const warehouseOptions: IOption[] = [];
  const depotOptions: IOption[] = [];
  facilities.forEach(({ id, name }) => {
    const [facility_type] = id.split('/');
    if (facility_type === 'Depot') {
      depotOptions.push({ value: id, label: name });
    } else if (facility_type === 'Warehouse') {
      warehouseOptions.push({ value: id, label: name });
    }
  });
  return [
    {
      label: 'Depots',
      options: depotOptions,
    },
    {
      label: 'Warehouses',
      options: warehouseOptions,
    },
  ];
};

export const FacilityPicker: React.FC<{
  id: string;
  facilityID?: string;
  facilities: Workforce__FacilityFragment[];
  onChange(facilityID: string): void;
}> = ({ id, facilityID, facilities, onChange }) => {
  const groupedOptions: IGroupedOption[] = useMemo(() => getGroupedOptions(facilities), [facilities]);

  return (
    <select
      id={id}
      className="form-control"
      value={facilityID}
      onChange={(event) => {
        onChange(event.target.value);
      }}
    >
      <option value="">No Facility Selected</option>
      {groupedOptions.map(({ label: groupLabel, options }) => (
        <optgroup key={groupLabel} label={groupLabel}>
          {options.map(({ value, label: optionLabel }) => (
            <option key={value} value={value}>
              {optionLabel}
            </option>
          ))}
        </optgroup>
      ))}
    </select>
  );
};
