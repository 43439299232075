import template from '@admin/angular/templates/dispatcher_assignments_manager.html';

import angular from 'angular';
import { find, includes, remove, head } from 'lodash';

const app = angular.module('app');

app.component('dispatcherAssignmentsManager', {
  template,
  bindings: {
    dispatch: '<',
    users: '<',
    fieldShiftAssignments: '<',
    filters: '<',
    editing: '<',
    onDispatchUpdated: '&',
  },

  controller: [
    function () {
      this.$onInit = () => {
        this.search();
      };

      this.selection = null;

      const mover = (user) => includes(user.roles, 'mover');

      const selectable = (user) => mover(user) && includes(user.region_ids, this.filters.region_id);

      const partition = () => {
        this.sections = [
          { name: 'Assigned Shifts (Not Dispatched)', style: 'full', users: this.moversAssignedShifts() },
          { name: 'Assigned Shifts (Dispatched)', style: 'warning', users: this.moversAssignedDispatches() },
          { name: 'Not Assigned Shifts', style: 'none', users: this.moversNotAssignedShifts() },
          { name: 'Other Regions', style: 'default', users: this.moversInOtherRegions() },
          { name: 'Non-Movers', style: 'default', users: this.nonMovers() },
        ];
      };

      this.reset = () => {
        this.autocomplete = null;
        this.searching = null;
      };

      this.search = (text) => {
        this.autocomplete = text;
        if (text && text.length) {
          const regex = new RegExp(text, 'gi');
          this.searching = this.users.filter((user) => user.name.match(regex));
        } else {
          this.reset();
        }
        partition();
      };

      this.autosubmit = (text) => {
        if (text && text.length) {
          const user = head(this.moversAssignedShifts()) || head(this.moversNotAssignedShifts());
          if (user) {
            this.reassign(this.selection, user);
          }
        }
      };

      this.moversAssignedDispatches = () =>
        (this.searching || this.users).filter(
          (user) =>
            selectable(user) &&
            this.fieldShiftAssignments
              .filter((assignment) => assignment.dispatch_ids.length > 0)
              .some((assignment) => assignment.user_id === user.id),
        );

      this.moversAssignedShifts = () =>
        (this.searching || this.users).filter(
          (user) =>
            selectable(user) &&
            this.fieldShiftAssignments
              .filter((assignment) => assignment.dispatch_ids.length === 0)
              .some((assignment) => assignment.user_id === user.id),
        );

      this.moversNotAssignedShifts = () =>
        (this.searching || this.users).filter(
          (user) =>
            selectable(user) && !this.fieldShiftAssignments.some((assignment) => assignment.user_id === user.id),
        );

      this.moversInOtherRegions = () =>
        (this.searching || this.users).filter((user) => !selectable(user) && mover(user));

      this.nonMovers = () => (this.searching || this.users).filter((user) => !mover(user));

      this.select = (assignment) => {
        if (!this.editing) {
          return;
        }
        this.selection = assignment;
        if (this.selection.user) {
          this.autocomplete = this.selection.user.name;
          this.search(this.selection.user.name);
        }
      };

      this.deselect = () => {
        if (!this.selection.user || !this.selection.role) {
          remove(this.dispatch.assignments, this.selection);
        }
        this.selection = null;
        this.reset();
        this.onDispatchUpdated(this.dispatch);
      };

      this.selected = (assignment) => this.selection === assignment;

      this.reassign = (assignment, user) => {
        if (assignment.user !== user) {
          Object.assign(assignment, { user, id: null });
        }
        this.deselect();
      };

      this.remove = (assignment) => {
        if (assignment.id) {
          Object.assign(assignment, { _destroy: true });
        } else {
          remove(this.dispatch.assignments, assignment);
        }
        this.deselect();
        this.onDispatchUpdated(this.dispatch);
      };

      this.insert = () => {
        const role = find(this.dispatch.assignments, (assignment) => assignment.role === 'lead') ? 'member' : 'lead';
        const assignment = { role };
        this.dispatch.assignments.push(assignment);
        this.select(assignment);
        this.onDispatchUpdated(this.dispatch);
      };

      this.showHoursWorked = (assignment) => {
        this.activeAssignment = assignment;
      };

      this.hideHoursWorked = () => {
        this.activeAssignment = null;
      };
    },
  ],
});
